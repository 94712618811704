import ada from "./modules/ada";
import astra from "./modules/astra";
import facetwp from "./modules/facetwp";
// import contact from "./modules/dialogs/contact";
import video from "./modules/dialogs/video";
import faq from "./modules/faq";
import pagination from "./modules/pagination";
import posts from "./modules/posts";
import projects from "./modules/projects";
import sliderBanner from "./modules/sliders/banner";
import sliderGallery from "./modules/sliders/gallery";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderProduct from "./modules/sliders/product";
import sliderTestimonial from "./modules/sliders/testimonial";
import utils from "./modules/utils";

// Utils
// Dev helpers
utils.e10kDevHelpers();

// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(
		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
	)
);

// Video fade
utils.e10kVideoFadeIn(document.querySelector(".background-video"));

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
// ada.init();

// Astra - Theme scripts
astra.init();

// Dialogs
// contact.init();
video.init();

// FacetWP - Plugin scripts
facetwp.init();

// FAQ
faq.init();

// Pagination
// pagination.init();

// Posts
// posts.init();

// Projects
projects.init();

// Sliders
sliderBanner.init();
sliderGallery.init();
sliderGrid.init();
sliderLogo.init();
sliderProduct.init();
sliderTestimonial.init();
