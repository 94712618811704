function init() {
	// Handle project market select field redirection
	const projectMarketNavigation = document.querySelector(
		".project-market-navigation"
	);

	if (projectMarketNavigation) {
		const handleProjectMarketNavigationChange =
			projectMarketNavigationRedirect(projectMarketNavigation);
	}
}

function projectMarketNavigationRedirect(navigation) {
	const selectField = navigation.querySelector("select");
	console.log(selectField);
	selectField.addEventListener("change", () => {
		const url = selectField.value;

		if (url) {
			window.location = url;
		}

		return false;
	});
}

export default { init };
