import utils from "../utils";

function init() {
	const videoDialogEls = document.querySelectorAll(".video-dialog");

	if (videoDialogEls) {
		const videoDialog = dialogEventListeners({
			open: document.querySelectorAll(".video-dialog-link"),
			dialog: videoDialogEls,
			close: document.querySelectorAll(".video-dialog .close")
		});
	}
}

function autoplayVideo(dialog) {
	// console.log("Autoplay!");
	const iframe = dialog.querySelector("iframe");

	// YouTube
	const iframeDataSrc = iframe.getAttribute("data-src");
	if (
		iframeDataSrc !== null &&
		iframeDataSrc.includes("youtube") &&
		!iframeDataSrc.includes("autoplay")
	) {
		// Add enable api, rel (show only playlist related videos, and autoplay)
		iframe.setAttribute(
			"data-src",
			iframeDataSrc + "&enablejsapi=1&rel=0&autoplay=1"
		);

		iframe.contentWindow.postMessage(
			'{"event":"command","func":"playVideo","args":""}',
			"*"
		);
	}

	// Vimeo
	const iframeSrc = iframe.getAttribute("src");
	if (
		iframeSrc !== null &&
		iframeSrc.includes("vimeo") &&
		!iframeSrc.includes("autoplay")
	) {
		iframe.setAttribute("src", iframeSrc + "&autoplay=1");
	}
}

function stopVideo(dialog) {
	// console.log("Stop video!");
	const iframe = dialog.querySelector("iframe");

	// YouTube
	const iframeDataSrc = iframe.getAttribute("data-src");
	if (
		iframeDataSrc !== null &&
		iframeDataSrc.includes("youtube") &&
		iframeDataSrc.includes("autoplay")
	) {
		iframe.setAttribute(
			"data-src",
			iframeDataSrc.replace("&autoplay=1", "")
		);

		iframe.contentWindow.postMessage(
			'{"event":"command","func":"stopVideo","args":""}',
			"*"
		);
	}

	// Vimeo
	const iframeSrc = iframe.getAttribute("src");
	if (
		iframeSrc !== null &&
		iframeSrc.includes("vimeo") &&
		iframeSrc.includes("autoplay")
	) {
		iframe.setAttribute("src", iframeSrc.replace("&autoplay=1", ""));
	}
}

function openDialog(e) {
	// console.log("Open dialog!");

	const item = e.target.closest(".item, .column");
	const dialog = item.querySelector("dialog");

	e.preventDefault();
	dialog.showModal();
	autoplayVideo(dialog);
}

// Close dialog
function closeDialog(e) {
	// console.log("Close dialog!");

	const item = e.target.closest(".item, .column");
	const dialog = item.querySelector("dialog");

	dialog.close();
	stopVideo(dialog);
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (e) => {
			openDialog(e, args.dialog);
		});
	});

	// Close
	// On ::backdrop click
	args.dialog.forEach((el) => {
		el.addEventListener("click", (e) => {
			if (e.target.nodeName === "DIALOG") {
				closeDialog(e);
			}
		});
	});

	// On close button click
	args.close.forEach((el) => {
		el.addEventListener("click", (e) => {
			closeDialog(e);
		});
	});
}

export default { init };
