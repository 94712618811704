import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("Product slider");

	const productSliderEls = document.querySelectorAll(
		".product-category-slider.embla"
	);
	if (productSliderEls) {
		productSliderEls.forEach((el) => {
			productSlider(el);
		});
	}
}

function productSlider(el) {
	const wrap = el;
	const viewPort = wrap.querySelector(".embla__viewport");
	const prevBtn = wrap.querySelector(".embla__button--prev");
	const nextBtn = wrap.querySelector(".embla__button--next");
	// const dots = wrap.querySelector(".embla__dot_navigation");
	const embla = EmblaCarousel(
		viewPort,
		{
			active: true,
			align: "start",
			// breakpoints: {
			// 	"(min-width: 768px)": { active: false }
			// },
			containScroll: "trimSnaps",
			dragFree: true
			// loop: true
		},
		[ClassNames()]
	);

	// Previous/next navigation
	const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
		embla,
		prevBtn,
		nextBtn
	);

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		embla,
		prevBtn,
		nextBtn
	);

	// Dot navigation
	// const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
	// 	embla,
	// 	dots
	// );

	// Autoplay
	// const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
