function init() {
	// console.log("FacetWP");

	const specsAndDesignBlock = document.querySelectorAll(
		".block-downloads.layout-specs-and-drawings"
	);

	if (specsAndDesignBlock.length) {
		// Allow filters to be toggled, mainly for mobile
		toggleAllFilters(specsAndDesignBlock);

		// On facet load
		document.addEventListener("facetwp-loaded", function () {
			// Show/hide filters if options exist
			showHideFilters();

			// Smoothscroll
			smoothScroll(specsAndDesignBlock);
		});

		// On facet refresh
		// document.addEventListener("facetwp-refresh", function () {
		// });
	}
}

function toggleAllFilters(block) {
	const filtersToggle = block[0].querySelector(".sidebar .filters-toggle");
	const sidebar = block[0].querySelector(".sidebar");

	filtersToggle.addEventListener("click", (e) => {
		if (!sidebar.classList.contains("show-filters")) {
			sidebar.classList.remove("hide-filters");
			sidebar.classList.add("show-filters");
		} else {
			sidebar.classList.remove("show-filters");
			sidebar.classList.add("hide-filters");
		}
	});
}

function showHideFilters() {
	Object.keys(FWP.settings.num_choices).forEach(function (key) {
		const val = FWP.settings.num_choices[key];
		const parent = document
			.querySelector(".facetwp-facet-" + key)
			.closest(".facetwp-filter");

		if (val !== 0) {
			parent.classList.remove("no-options");
		} else {
			parent.classList.add("no-options");
		}
	});
}

function smoothScroll(block) {
	if (FWP.loaded) {
		const results = block[0].querySelector("#results");

		results.scrollIntoView({
			behavior: "smooth"
		});
	}
}

export default { init };
